import React from 'react'
import Skeleton from 'react-loading-skeleton'

const PatientConsultationLoader = () => {
  return (
    <div className='w-45 md:w-[350px] p-3 md:px-5 md:py-4 border-[1px] border-gray-200 rounded-lg'>
        <Skeleton width={150} />
        <div>
            <div className='flex gap-10 mt-3'>
                <Skeleton width={150}/>
                <Skeleton width={100} height={70}/>
            </div>
            <div>
                <Skeleton width={150} height={30} borderRadius={8}/>
            </div>
        </div>
    </div>
  )
}

export default PatientConsultationLoader