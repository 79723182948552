import React from 'react';
import Skeleton from 'react-loading-skeleton';


const RewardsEarnedLoader = () => {
    return (
        <div className='border border-gray-100 p-3 rounded-lg'>
            <div className='flex gap-2'>
                <Skeleton width={120} />
                <Skeleton width={20} height={20} circle />
            </div>

            <div className="flex my-5">
                <div className="flex items-center">
                    <Skeleton count={1} height={20} width={20} circle />
                    <Skeleton count={1} height={6} width={95} />
                </div>
                <div className="flex items-center">
                    <Skeleton count={1} height={20} width={20} circle />
                    <Skeleton count={1} height={6} width={95} />
                </div>
                <div className="flex items-center">
                    <Skeleton count={1} height={20} width={20} circle />
                    <Skeleton count={1} height={6} width={95} />
                </div>
                <div className="flex items-center">
                    <Skeleton count={1} height={20} width={20} circle />
                    <Skeleton count={1} height={6} width={95} />
                </div>
                <div className="flex items-center">
                    <Skeleton count={1} height={20} width={20} circle />
                    <Skeleton count={1} height={6} width={95} />
                </div>
                <div className="flex items-center">
                    <Skeleton count={1} height={20} width={20} circle />
                    <Skeleton count={1} height={6} width={95} />
                </div>
                <div className="flex items-center">
                    <Skeleton count={1} height={20} width={20} circle />
                    <Skeleton count={1} height={6} width={95} />
                    <Skeleton count={1} height={20} width={20} circle />
                </div>
            </div>
            <Skeleton width={180} height={40}/>
        </div>
    )
}

export default RewardsEarnedLoader